<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Create New OrderItem</v-card-title>
    <v-row>
      <v-col cols="6"><v-text-field dense outlined label="OrderId" v-model="item.order_id"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Name" v-model="item.name"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Link" v-model="item.link"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Price" v-model="item.price"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Quantity" v-model="item.quantity"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Description" v-model="item.description"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Weight" v-model="item.weight"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Length" v-model="item.length"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Width" v-model="item.width"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Height" v-model="item.height"/></v-col>
</v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processAdd">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'CreateOrderItem',
  props: ['onCreated', 'onCancel'],
  data: () => ({
    item: {}
  }),
  methods: {
    ...mapActions({
      createItem: 'order_item/create'
    }),
  },
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem) this.item = this.selectedItem;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
